import moment from 'moment'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'

export default () => {
  const { makeToast } = useToast()

  const getSettleHistoryList = () => {
    api.getSettleHistoryList().then(response => {
      state.settleHistory.historyList = response.data.data
    }).catch(() => {
      makeToast('danger', '정산엑셀 다운로드 내역을 가져오는데 실패했습니다')
    })
  }

  const completeTempSettle = () => {
    api.completeTempSettle({
      history_idx: state.settleHistory.targetIdx,
    }).then(() => {
      makeToast('primary', '정산완료처리에 성공했습니다')
      getSettleHistoryList()
    }).catch(() => {
      makeToast('danger', '정산완료처리에 실패했습니다')
    })
  }

  const deleteHistory = () => {
    api.deleteHistory({
      history_idx: state.settleHistory.targetIdx,
    }).then(() => {
      makeToast('primary', '삭제처리에 성공했습니다')
      getSettleHistoryList()
    }).catch(() => {
      makeToast('danger', '삭제처리에 실패했습니다')
    })
  }

  const getCanDelete = target => !target.is_complete

  const getCanSettle = target => !target.is_complete

  const turnOnSidebar = () => {
    getSettleHistoryList()
    state.settleHistory.isVisibleSidebar = true
  }
  const turnOffSidebar = () => {
    state.settleHistory.isVisibleSidebar = false
  }

  const turnOnDeleteModal = targetIdx => {
    state.settleHistory.targetIdx = targetIdx
    state.settleHistory.isVisibleDeleteModal = true
  }
  const turnOffDeleteModal = () => {
    state.settleHistory.isVisibleDeleteModal = false
  }

  const turnOnCompleteModal = targetIdx => {
    state.settleHistory.targetIdx = targetIdx
    state.settleHistory.isVisibleCompleteModal = true
  }
  const turnOffCompleteModal = () => {
    state.settleHistory.isVisibleCompleteModal = false
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    turnOnDeleteModal,
    turnOffDeleteModal,
    turnOnCompleteModal,
    turnOffCompleteModal,
    getSettleHistoryList,
    completeTempSettle,
    deleteHistory,
    getCanDelete,
    getCanSettle,
  }
}
