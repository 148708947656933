<template>
  <TagbyPrimaryModal
    :visible="state.excelDownload.isVisible"
    title="엑셀 다운로드"
    @hidden="turnOffModal"
    @ok="downloadTempSettleExcel"
  >
    <div class="text-danger mb-1">
      참고: 사업자정보가 등록되지 않은 사용자에 대해서는 엑셀에 표시되지 않음
    </div>

    <BFormGroup :state="isValidMemo">
      <BFormInput
        v-model="state.excelDownload.memo"
        :state="isValidMemo"
        placeholder="input memo (required)"
      />
    </BFormGroup>
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import state from '../../state'
import useExcelDownload from '../../useExcelDownload'

export default defineComponent({
  components: {
    BFormInput,
    BFormGroup,
    TagbyPrimaryModal,
  },
  setup() {
    const {
      turnOffModal,
      downloadTempSettleExcel,
    } = useExcelDownload()

    const isValidMemo = computed(() => Boolean(state.excelDownload.memo))

    return {
      state,
      isValidMemo,
      turnOffModal,
      downloadTempSettleExcel,
    }
  },
})
</script>
