<template>
  <TagbySidebar
    :visible="state.settleHistory.isVisibleSidebar"
    @hidden="turnOffSidebar"
  >
    <template #content>
      <div class="text-danger">
        * 정산금액 엑셀다운로드한 목록이 표시됩니다. 반드시 엑셀에 맞춰 정산이 모두 완료된 후에 완료 버튼을 클릭해주세요.
      </div>
      <div class="text-danger">
        * 만약 다운로드한 엑셀과 다르게 정산금액에 변동이 생겼다면 개발팀에 문의 바랍니다.
      </div>
      <BTable
        :fields="fields"
        :items="state.settleHistory.historyList"
        show-empty
        small
      >
        <template #cell(actions)="data">
          <TagbyTableAction>
            <TagbyTableActionItem
              text="완료"
              :disabled="data.item.is_complete"
              @click="turnOnCompleteModal(data.item.idx)"
            />
            <TagbyTableActionItem
              text="삭제"
              :disabled="data.item.is_complete"
              @click="turnOnDeleteModal(data.item.idx)"
            />
          </TagbyTableAction>
        </template>
      </BTable>
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import state from '../../state'
import useSettleHistory from '../../useSettleHistory'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    TagbySidebar,
    BTable,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      turnOffSidebar,
      turnOnCompleteModal,
      turnOnDeleteModal,
    } = useSettleHistory()

    const fields = [
      'idx', 'text', 'is_complete', 'actions',
    ]

    return {
      state,
      fields,
      turnOffSidebar,
      turnOnCompleteModal,
      turnOnDeleteModal,
    }
  },
})
</script>
