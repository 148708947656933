<template>
  <TagbyPrimaryModal
    :visible="state.settleHistory.isVisibleCompleteModal"
    title="완료처리"
    content="완료처리하시겠습니까?"
    @ok="completeTempSettle"
    @hidden="turnOffCompleteModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useSettleHistory from '../../useSettleHistory'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      completeTempSettle,
      turnOffCompleteModal,
    } = useSettleHistory()

    return {
      state,
      completeTempSettle,
      turnOffCompleteModal,
    }
  },
})
</script>
