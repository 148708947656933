<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-success"
          class="mr-1"
          :disabled="state.excelDownload.isDownloading"
          @click="turnOnModal"
        >
          <BSpinner
            v-if="state.excelDownload.isDownloading"
            small
          />
          정산금액 다운로드
        </BButton>

        <BButton
          variant="outline-secondary"
          class="mr-1"
          @click="turnOnSidebar"
        >
          정산 완료 처리
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'campaign-expg-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(adv)="data">
        <div>
          <div>
            {{ data.item.adv_name }}
          </div>
          <small class="text-muted">{{ data.item.adv_company_name }}</small>
        </div>
      </template>

      <template #cell(title)="data">
        <div>
          <div>
            {{ data.item.title }}
          </div>
          <small class="text-muted">{{ data.item.brand_name }}</small>
        </div>
      </template>

      <template #cell(sales_count)="data">
        {{ data.item.sales_count }} 개
      </template>

      <template #cell(product_quantity)="data">
        {{ data.item.product_quantity }} 개
      </template>

      <template #cell(step)="data">
        <div>
          <div :class="data.value === 'SELLING' ? 'text-success' : null">
            {{ data.value }}
          </div>
          <div v-if="data.item.manage_step != null">
            ({{ data.item.manage_step }})
          </div>
        </div>
      </template>

      <template #cell(sns_channel)="data">
        <BAvatarGroup size="32px">
          <BAvatar
            v-if="hasChannel(data.value, 'INSTAGRAM')"
            rounded
            variant="warning"
            class="pull-up"
          >
            <span>INS</span>
          </BAvatar>
          <BAvatar
            v-if="hasChannel(data.value, 'BLOG')"
            rounded
            variant="success"
            class="pull-up"
          >
            <span>BLG</span>
          </BAvatar>
        </BAvatarGroup>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(registered_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>

    <ExcelDownloadModal />
    <SettleHistorySidebar />
    <SettleCompleteModal />
    <SettleDeleteModal />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
  BAvatarGroup,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import state from './state'
import getters from './getters'
import useExcelDownload from './useExcelDownload'
import ExcelDownloadModal from './components/Modals/ExcelDownloadModal.vue'
import useSettleHistory from './useSettleHistory'
import SettleHistorySidebar from './components/Sidebars/SettleHistorySidebar.vue'
import SettleCompleteModal from './components/Modals/SettleCompleteModal.vue'
import SettleDeleteModal from './components/Modals/SettleDeleteModal.vue'

export default defineComponent({
  components: {
    BLink,
    BButton,
    BAvatarGroup,
    BAvatar,
    BSpinner,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    ExcelDownloadModal,
    SettleHistorySidebar,
    SettleCompleteModal,
    SettleDeleteModal,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    const hasChannel = (snsChannel, channelValue) => {
      if (!snsChannel) {
        return false
      }
      return snsChannel.includes(channelValue)
    }

    const {
      turnOnModal,
    } = useExcelDownload()

    const {
      turnOnSidebar,
    } = useSettleHistory()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
      hasChannel,
      turnOnModal,
      turnOnSidebar,
    }
  },
})
</script>
