import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from './state'
import api from './api'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: '제목',
      paramName: 'title_list',
    },
    {
      label: 'IDX',
      paramName: 'idx_list',
    },
    {
      label: '단계',
      paramName: 'step_list',
      format: 'checkbox',
      options: [
        {
          text: 'SELLING',
          value: 'SELLING',
        },
        {
          text: 'SOLD',
          value: 'SOLD',
        },
        {
          text: 'PENDING',
          value: 'PENDING',
        },
      ],
    },
    {
      label: '상태',
      paramName: 'state_list',
      format: 'checkbox',
      options: [
        {
          text: 'ACTIVE',
          value: 'ACTIVE',
        },
        {
          text: 'INACTIVE',
          value: 'INACTIVE',
        },
        {
          text: 'WRITING',
          value: 'WRITING',
        },
      ],
    },
    {
      label: '사용자이름',
      paramName: 'adv_name_list',
    },
    {
      label: '사용자회사',
      paramName: 'adv_company_list',
    },
    {
      label: '브랜드',
      paramName: 'brand_list',
    },
    {
      label: '등록일시',
      paramName: 'registered_at_list',
      format: 'date',
    },
  ]

  const searchList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.items = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      state.isBusy = false
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'adv', label: '사용자' },
    { key: 'title', label: '제목' },
    { key: 'sns_channel', label: 'SNS' },
    { key: 'sales_count', label: '판매량' },
    { key: 'product_quantity', label: '재고' },
    { key: 'registered_at', label: '등록일시' },
    { key: 'step', label: '단계' },
    { key: 'state', label: '상태' },
  ]

  const changePage = params => {
    state.currentPage = params.page
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
