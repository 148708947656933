<template>
  <TagbyDangerModal
    :visible="state.settleHistory.isVisibleDeleteModal"
    title="삭제"
    content="삭제하시겠습니까?"
    @ok="deleteHistory"
    @hidden="turnOffDeleteModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import useSettleHistory from '../../useSettleHistory'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      deleteHistory,
      turnOffDeleteModal,
    } = useSettleHistory()

    return {
      state,
      deleteHistory,
      turnOffDeleteModal,
    }
  },
})
</script>
