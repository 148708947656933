import {
  reactive,
} from '@vue/composition-api'

export default reactive({
  currentPage: null,
  perPage: null,
  totalRows: null,
  items: [],
  admList: [],
  isBusy: false,
  filterParams: {},
  sortBy: '-idx',
  isLoading: false,
  excelDownload: {
    isDownloading: false,
    isVisible: false,
    memo: null,
  },
  settleHistory: {
    isVisibleSidebar: false,
    historyList: [],
    targetIdx: null,
    isVisibleDeleteModal: false,
    isVisibleCompleteModal: false,
  },
})
