import moment from 'moment'

import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import downloadFile from '@/utils/downloadFile'

export default () => {
  const { makeToast } = useToast()

  const turnOnModal = () => {
    state.excelDownload.isVisible = true
    state.excelDownload.memo = null
  }
  const turnOffModal = () => {
    state.excelDownload.isVisible = false
  }

  const downloadTempSettleExcel = () => {
    state.excelDownload.isDownloading = true
    api.downloadTempSettleExcel({
      memo: state.excelDownload.memo,
    }).then(response => {
      const fileName = `expg_settle_${moment().format('YYYYMMDDHHmmss')}.xlsx`
      const file = new File([response.data], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      downloadFile(file, fileName)
    }).catch(() => {
      makeToast('danger', '엑셀을 다운로드하는데 실패 했습니다')
    }).finally(() => {
      state.excelDownload.isDownloading = false
    })
  }

  return {
    turnOnModal,
    turnOffModal,
    downloadTempSettleExcel,
  }
}
